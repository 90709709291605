﻿// Ad Spots
$('.spg-ad-spot a, a.spg-ad-spot').on('click', function (e) {
  if(typeof ga !== 'undefined') {
    const htmlBlobParent = e.target.closest('.HtmlContentClass');

    ga.getAll()[0].send('event', {
      eventCategory: $(htmlBlobParent).attr('name'),
      eventAction: 'Click',
      eventLabel: $(htmlBlobParent).find('img').attr('alt') + ', ' + $(htmlBlobParent).attr('data-id') + ', CTA' + parseInt($(e.target).closest('a').attr('data-link-num'), 10)
    });
  }
});
// Service Spots
$('.service-spot a').on('click', function (e) {
  if(typeof ga !== 'undefined') {
    const htmlBlobParent = e.target.closest('.HtmlContentClass');

    ga.getAll()[0].send('event', {
      eventCategory: $(htmlBlobParent).attr('name') + (1 + parseInt($(htmlBlobParent).attr('data-slick-index'), 10)).toString(),
      eventAction: 'Click',
      eventLabel: $(htmlBlobParent).find('img').attr('alt') + ', ' + $(htmlBlobParent).attr('data-id')
    });
  }
});
// Promo Spots
$('.explore-slider .HtmlContentClass a').on('click', function (e) {
  if(typeof ga !== 'undefined') {
    const htmlBlobParent = e.target.closest('.HtmlContentClass');

    ga.getAll()[0].send('event', {
      eventCategory: $(htmlBlobParent).attr('name'),
      eventAction: 'Click',
      eventLabel: $(htmlBlobParent).find('img').attr('alt') + ', ' + $(htmlBlobParent).attr('data-id') + ', CTA' + parseInt($(e.target).attr('data-link-num'), 10)
    });
  }
});
// Hero
$('.hero-container .HtmlContentClass .hero-text a').on('click', function (e) {
  if(typeof ga !== 'undefined') {
    const htmlBlobParent = e.target.closest('.HtmlContentClass');

    ga.getAll()[0].send('event', {
      eventCategory: $(htmlBlobParent).attr('name'),
      eventAction: 'Click',
      eventLabel: $(htmlBlobParent).find('img').attr('alt') + ', ' + $(htmlBlobParent).attr('data-id') + ', CTA' + parseInt($(e.target).closest('a').attr('data-link-num'), 10)
    });
  }
});
